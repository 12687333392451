<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-06-07 15:41:32
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-09 13:56:10
--> 
<template>
  <div class="category" v-if="!showEditView">
    <el-card class="box">
      <!-- <el-steps :active="active" finish-status="success">
      <el-step title="类目选择"></el-step>
      <el-step title="编辑内容"></el-step>
    </el-steps> -->
      <div>选择分类</div>
    </el-card>

    <el-card class="box">
      <el-select
        size="large"
        v-model="value"
        placeholder="输入关键字查询类目"
        remote
        filterable
        clearable
        @change="selectChange"
        :remote-method="searchCategory"
      >
        <el-option
          v-for="item in arrayList"
          :key="item.id"
          :label="item.full_name"
          :value="item.id"
        >
          <div
            v-html="item.full_name.replace(query.q, `<em>${query.q}</em>`)"
          ></div>
        </el-option>
      </el-select>

      <el-cascader-panel
        ref="cascader"
        :props="props"
        clearable
        @change="cascaderChange"
      />
    </el-card>

    <el-card class="box">
      <el-alert type="info" title="当前选择:" :closable="false">
        <div style="color: var(--el-color-primary)">
          {{ current?.full_name }}
        </div>
      </el-alert>
    </el-card>
    <el-card class="box">
      <el-button
        type="primary"
        size="large"
        @click="onChooseOk"
        :loading="saving"
        >确认发布该类商品</el-button
      >
    </el-card>
  </div>
  <step-edit v-if="showEditView" :item="goods"></step-edit>
</template>

<script>
import StepEdit from "./step_edit.vue";
export default {
  components: {
    StepEdit,
  },
  data() {
    var that = this;
    return {
      saving: false,
      //select 选择的结果
      value: "",
      query: {
        q: "",
      },
      //显示产品编辑页面
      showEditView: false,
      current: null,
      //关键字搜索查询结果
      arrayList: [],
      props: {
        emitPath: false,
        lazy: true,
        lazyLoad(node, resolve) {
          that.$http
            .get(
              `seller/v1/common/categories?fatherId=${
                node.value ? node.value : 0
              }&q=`
            )
            .then((res) => {
              if (res.code == 0) {
                var nodes = res.data.map((item) => {
                  return {
                    value: item["id"],
                    label: item["name"],
                    leaf: item["is_leaf"],
                    full_name: item["full_name"],
                  };
                });
                resolve(nodes);
              }
            });
        },
      },
      //创建后返回的产品对象
      goods: null,
    };
  },
  created() {
    var query = this.$route.query;
    if (query.id && !query.cat_id) {
      this.showEditView = true;
    } else {
      this.showEditView = false;
    }
  },
  methods: {
    /**
     * 下拉框搜索
     */
    searchCategory(q) {
      this.query.q = q;
      this.$http.get("seller/v1/common/select-category?q=" + q).then((res) => {
        if (res.code == 0) {
          this.arrayList = res.data;
        }
      });
    },
    /**
     * 下拉框选择更改
     * @param {*} item
     */
    selectChange(value) {
      if (value) {
        this.$refs.cascader.clearCheckedNodes();
        var item = this.arrayList.find((o) => o.id == value);
        this.current = item;
      }
    },

    /**
     * 确认选择发布该类商品
     */
    onChooseOk() {
      if (this.current == null) {
        return;
      }
      if (!this.current.is_leaf) {
        this.$message({
          message: "请选择完整分类",
        });
        return;
      }

      this.saving = true;
      this.$http
        .post("seller/v1/goods/create", {
          category_id: this.current.id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.showEditView = true;
            var data = res.data;
            this.$router.push({
              path: "/goods/edit",
              replace: true,
              query: { id: data.id, action: "add" },
            });
            this.goods = res.data;
          }
        })
        .catch(() => {
          this.saving = false;
        });
    },

    /**
     * 选中
     */
    cascaderChange(item) {
      if (item) {
        this.value = "";
        var nodes = this.$refs.cascader.getCheckedNodes();
        var data = nodes[0].data;
        this.current = {
          id: data.value,
          full_name: data.full_name,
          is_leaf: data.leaf,
          name: data.label,
        };
      }
    },
  },
};
</script>

<style>
.category .el-cascader-panel {
  height: 300px;
}
.category .el-cascader-menu__wrap.el-scrollbar__wrap {
  /* height: 204px; */
  height: 100%;
}
.category .el-select {
  display: block;
  margin-bottom: 20px;
}
.el-select-dropdown em {
  color: #f56c6c;
  font-style: normal;
}
</style>