<!--
 * @Description: 编辑商品
 * @Author: 琢磨先生
 * @Date: 2022-05-30 23:56:23
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-16 20:51:28
-->

<template>
  <div class="edit-goods" v-loading="loading">
    <el-card class="box alert-category">
      <div style="margin-bottom: 20px">{{ action == "update" ? "修改商品" : "发布新商品" }}</div>
      <el-alert :title="`选择的类目：${form.category?.full_name ?? ''}`" type="info" :closable="false">
        <!-- <el-button class="btn-change-category" type="text" @click="changeCategory">更改</el-button> -->
      </el-alert>
    </el-card>
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-card class="box">
        <el-card shadow="never">
          <div class="box-title">基本信息</div>
          <el-form-item label="商品轮播图" class="is-required">
            <edit-banner :images="form.images" @change="bannerChange"></edit-banner>
          </el-form-item>
          <el-form-item label="商品标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入商品标题"></el-input>
          </el-form-item>
          <!-- 商品属性 -->
          <el-form-item label="商品属性">
            <el-alert type="info" title :closable="false">
              <div>请准确填写属性！(注：带*为重要属性)。</div>

              <div class="property-grid">
                <div class="item">
                  <el-form-item label="品牌" class="is-required">
                    <el-select
                      clearable
                      remote
                      filterable
                      :filter-method="remoteBrand"
                      v-model="form.brand_id"
                      placeholder="关键字搜索并选择"
                    >
                      <el-option
                        :label="item.name"
                        :value="item.id"
                        v-for="item in brandList"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  class="item"
                  v-for="item in propertyList.filter((x) => x.type != 1)"
                  :key="item.id"
                >
                  <el-form-item :label="item.name" :class="item.is_required ? 'is-required' : ''">
                    <el-col :span="20">
                      <!-- 0 单选 -->
                      <el-select
                        v-model="item.value_id"
                        placeholder
                        clearable
                        v-if="item.input_type == 0"
                      >
                        <el-option
                          :label="x.value"
                          :value="x.id"
                          v-for="x in item.values"
                          :key="x.id"
                        ></el-option>
                      </el-select>
                      <!-- 1：多选 -->
                      <el-select
                        v-model="item.value_ids"
                        placeholder
                        clearable
                        multiple
                        v-if="item.input_type == 1"
                      >
                        <el-option
                          :label="x.value"
                          :value="x.id"
                          v-for="x in item.values"
                          :key="x.id"
                        ></el-option>
                      </el-select>

                      <!-- 2 文本输入 -->
                      <el-input v-model="item.value" placeholder v-if="item.input_type == 2">
                        <template #append v-if="item.unit">
                          {{
                          item.unit
                          }}
                        </template>
                      </el-input>
                    </el-col>
                  </el-form-item>
                </div>
              </div>
            </el-alert>
          </el-form-item>
          <!-- 商品详情 -->
          <el-form-item label="商品详情" class="is-required">
            <edit-decoration :goods="form" @change="decorationChange"></edit-decoration>
          </el-form-item>
        </el-card>
      </el-card>
      <!-- 规格与库存 -->
      <el-card class="box">
        <el-card shadow="never">
          <div class="box-title">规格与库存</div>
          <edit-sku :properties="propertyList" :goods="form" @change="skuChange"></edit-sku>
        </el-card>
      </el-card>

      <el-card class="box">
        <el-card shadow="never">
          <div class="box-title">服务与承诺</div>
          <el-form-item label="运费模板" prop="carriage_id" class="is-required">
            <el-radio-group v-model="form.carriage_id">
              <el-radio :label="default_carriage.id">默认模板</el-radio>
              <!-- <el-radio label="1">自定义</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="承诺发货时间" prop="shipment_limit_second">
            <el-radio-group v-model="form.shipment_limit_second">
              <el-radio :label="86400">24小时</el-radio>
              <el-radio :label="172800">48小时</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label prop="is_provide_invoice">
            <el-checkbox label v-model="form.is_provide_invoice">提供发票</el-checkbox>
          </el-form-item>
          <el-form-item label prop="is_warranty">
            <el-checkbox label v-model="form.is_warranty">保修服务</el-checkbox>
          </el-form-item>
          <el-form-item label prop="is_7day_return">
            <el-checkbox label v-model="form.is_7day_return">7天无理由退换货</el-checkbox>
          </el-form-item>
          <el-form-item label prop="is_stop">
            <el-checkbox label v-model="form.is_stop">暂停下单</el-checkbox>
          </el-form-item>
        </el-card>
      </el-card>

      <footer class="footer">
        <el-button type="primary" :loading="saving" @click="onConfirm">提交并上架</el-button>
        <el-button
          @click="onDraft"
          :loading="draft_saving"
          v-if="form.status == 0 || form.status == 1"
        >保存草稿</el-button>
      </footer>
    </el-form>
  </div>
</template>

<script>
import EditBanner from "./edit_banner.vue";
import EditDecoration from "./edit_decoration.vue";
import EditSku from "./edit_sku.vue";
export default {
  components: {
    EditBanner,
    EditDecoration,
    EditSku,
  },
  data() {
    return {
      loading: false,
      saving: false,
      draft_saving: false,
      action: "add",
      form: {},
      rules: {
        title: [
          {
            required: true,
            message: "标题必填",
            trigger: "blur",
          },
        ],
      },
      //类目属性列表
      propertyList: [],
      //详情图片
      decoration_list: [],
      //品牌列表
      brandList: [],
      //默认运费模板
      default_carriage: {},
    };
  },
  emits: ["change-category"],
  props: ["item"],
  created() {
    var query = this.$route.query;
    if (query.id) {
      this.id = query.id;
      this.action = query.action;
      this.loadDtl();
    }
    this.loadDefaultCarriage();
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.id = this.item.id;
          this.loadDtl();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 获取产品的详情
     */
    loadDtl() {
      this.loading = true;
      this.$http.get("seller/v1/goods/dtl?id=" + this.id).then((res) => {
        if (res.code == 0) {
          this.form = res.data;
          if (this.form.brand) {
            this.brandList.push(this.form.brand);
          }
          this.loadProperty();
          this.remoteBrand();
        }
        this.loading = false;
      });
    },

    /**
     * 获取类目下的属性
     */
    loadProperty() {
      this.$http
        .get("seller/v1/goods/cate/properties?cateId=" + this.form.category_id)
        .then((res) => {
          if (res.code == 0) {
            this.propertyList = res.data;
            if (this.form.properties && this.form.properties.length > 0) {
              this.propertyList.forEach((item) => {
                var property = this.form.properties.find(
                  (x) => x.property_id == item.id
                );
                if (property && property.input_type == item.input_type) {
                  if (item.input_type == 0) {
                    item.value_id = property.value_id;
                  } else if (item.input_type == 1) {
                    item.value_ids = property.value_ids;
                  } else if (item.input_type == 2) {
                    item.value = property.value_set;
                  }
                }
              });
            }
          }
        });
    },

    /**
     * 获取默认运费模板
     */
    loadDefaultCarriage() {
      this.$http.get("seller/v1/common/carriage/default").then((res) => {
        if (res.code == 0) {
          this.default_carriage = res.data;
        }
      });
    },

    /**
     * 修改类目
     */
    changeCategory() {
      this.$emit("change-category");
    },
    /**
     * 轮播图变动回调
     * @param {*} srcUrl
     */
    bannerChange(files) {
      this.form.images = files;
    },
    /**
     * 关键字搜索品牌
     */
    remoteBrand(q) {
      this.$http
        .get(
          "seller/v1/goods/cate/brand?cateId=" +
            this.form.category_id +
            "&q=" +
            q
        )
        .then((res) => {
          if (res.code == 0) {
            this.brandList = res.data;
          }
        });
    },
    /**
     * 详情图片编辑回调
     */
    decorationChange(values) {
      this.decoration_list = values;
    },
    /**
     * 规格与库存变动回调
     */
    skuChange(skus, obj) {
      this.form.skus = skus;
      this.form.store_count = obj.store_count;
      this.form.price = obj.price;
    },
    /**
     * 保存提交上架
     */
    onConfirm() {
      this.form.status = 2;
      this.onPostSave();
    },
    /**
     *  保存草稿
     */
    onDraft() {
      this.draft_saving = true;
      this.form.status = 1;
      this.onPostSave();
    },

    /**
     *  执行保存数据
     */
    onPostSave() {
      var list = this.propertyList.filter((x) => x.type != 1);
      // 属性内容
      var properties = [];
      list.forEach((item) => {
        if (item.value_id || item.value_ids || item.value) {
          var m = {
            input_type: item.input_type,
            property_id: item.id,
            property_name: item.name,
          };
          if (item.value_id) {
            m.value_id_set = item.value_id;
            var valItem = item.values.find((x) => x.id == item.value_id);
            m.value_set = valItem.value;
          }

          if (item.value_ids) {
            m.value_id_set = item.value_ids.join(",");
            m.value_set = item.values
              .filter((x) => item.value_ids.findIndex((o) => o == x.id) > -1)
              .map((v) => v.value)
              .join(",");
          }
          if (item.value) {
            m.value_set = item.value;
          }
          properties.push(m);
        }
      });
      console.log(list);

      //属性
      this.form.properties = properties;
      //商品详情
      if (this.decoration_list.length > 0) {
        this.form.mobile_decoration = JSON.stringify(this.decoration_list);
      }
      this.saving = true;

      this.$http.post("seller/v1/goods/save", this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.form.version = res.data.version;
          this.form.status = res.data.status;
          if (res.data.status == 2) {
            //上架成功跳转
            this.$router.push({
              path: "/goods",
              replace: true,
            });
          }
        }
        this.draft_saving = false;
        this.saving = false;
      });
    },
  },
};
</script>

<style >
.alert-category .el-alert__content {
  display: flex;
  align-items: center;
}

.el-alert__description {
  margin: 0 !important;
}

.btn-change-category {
  margin-left: 10px;
}

.box .box-title {
  padding-bottom: 20px;
}

.el-form .el-alert__content {
  width: 100% !important;
}
.property-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  row-gap: 20px;
}

.edit-goods {
  padding-bottom: 60px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 240px;
  right: 20px;
  height: 60px;
  border-top: 1px solid var(--el-border-color);
}
</style>